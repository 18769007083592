import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TitleH2 from "../components/globals/title-h2"

import video from "../videos/video-background-academia.mp4"
import posteracademia from "../images/poster-academia.jpg"
import QueEs from "../components/academia/que-es"
import ImageEsquema from "../components/academia/image-esquema"
import ImageProceso from "../components/academia/image-proceso"
import ImageProcesoMovil from "../components/academia/image-proceso-movil"
import BackgroundPerfiles from "../components/academia/background-perfiles"
import QuienesParticipan from "../components/academia/quienes-participan"
import ContactoAcademia from "../components/globals/contacto-academia"


import "../scss/academia-styles.scss"

const AcademiaDeSoftware = () => (
  <Layout footer={true} menuactive="academia">
    <SEO title="Academia de software" />
    <div className="video-background-container academia">
      <h1 className="message floating-1 bold">
        ¡Únete a nuestra <br />Academia de Software!
      </h1>
      <h2 className="subtitle floating-2">
        ¿Eres desarrollador y te interesa construir software de clase mundial?
      </h2>
      <video autoPlay loop muted poster={posteracademia}>
        <source src={video} type="video/mp4" />
      </video>
    </div>
    <ContactoAcademia />
    <div className="container padding-0">
      <div className="container-warped">
        <TitleH2 titleName="¿Qué es?" align="left"></TitleH2>
        <p className="txt-description">
          La Academia de software es un programa de aprendizaje acelerado dirigido
          a universitarios recién egresados o que estudian el último semestre de la carrera.<br /><br />
          Mediante la impartición de talleres teórico-prácticos tendrás la oportunidad
          de perfeccionar tus conocimientos y aprender a desarrollar tecnología de punta.
        </p>
      </div>
    </div>
    <QueEs />
    <div className="container padding-0">
      <div className="container-warped">
        <br /><br />
        <h3 className="h3-big text-orange">¡La Academia de software es un programa en el que podrás desarrollar tu talento y habilidades al máximo!</h3>
        <br /><br />
        <div className="block">
          <div className="block-item bkg-orange">
            <h3>1er mes</h3>
            <p>Asistirás a un taller impartido por un instructor</p>
          </div>
          <div className="block-item bkg-light-blue">
            <h3>2do mes</h3>
            <p>Participarás en el desarrollo de un proyecto interno</p>
          </div>
          <div className="block-item bkg-blue">
            <h3>3er mes</h3>
            <p>Participarás en el desarrollo de un proyecto con cliente</p>
          </div>
        </div>
        <br />
        <span className="txt-description">
          Tu esquema de compensación económica aplicará de la siguiente manera:
        </span>
      </div>
      
    </div>
    <div className="container-warped-agencia bkg-white">
      <div className="image-full">
        <ImageEsquema />
      </div>
    </div>
    <div className="container-warped padding-0 bkg-white margin-0">
      <TitleH2 titleName="¿Qué perfiles buscamos?" align="center bkg-white"></TitleH2>
    </div>
    <div className="bkg-white">
      <BackgroundPerfiles />
    </div>
    <div className="container">
      <div className="row">
        <div className="col txt-container">
          <div className="txt-description txt-description__fixed-styles padding-container">
            <h2>¿Quiénes participan?</h2>
            <p>Participan universitarios:</p>
            <ul className="list-1">
              <li>Recién egresados o que estudian el último semestre de la carrera</li>
              <li>Que residen en la ciudad de México o Área Metropolitana</li>
              <li>Con conocimientos básicos de Android</li>
              <li>Que tienen disponibilidad de tiempo completo</li>
            </ul>
          </div>
        </div>
        <div className="col image__responsive">
          <QuienesParticipan />
        </div>
      </div>
    </div>
    <div className="container padding-0">
      <div className="container-warped">
        <TitleH2 titleName="¿Qué ofrecemos?" align="left"></TitleH2>
        <p className="txt-description">
          Te ofrecemos un plan de entrenamiento de 3 meses con las siguientes características:
        </p>
      </div>
    </div>
    <div className="container-warped-agencia center bkg-white">
      <div className="matrix">
        <div className="matrix_item capacitacion">
          <p><span>Obtendrás <span className="bold">capacitación</span> especializada en la plataforma Android</span></p>
        </div>
        <div className="matrix_item instructores">
          <p><span>Contarás con el apoyo de <span className="bold">instructores</span> altamente <span className="bold">especializados</span> y con amplia experiencia</span></p>
        </div>
        <div className="matrix_item aplicaciones">
          <p><span>Construirás <span className="bold">aplicaciones empresariales</span></span></p>
        </div>
        <div className="matrix_item proyecto">
          <p><span>Participarás en todas las fases del ciclo de vida de un <span className="bold">proyecto</span></span></p>
        </div>
        <div className="matrix_item educacion">
          <p><span>Tendrás acceso a plataformas de <span className="bold">educación en línea</span></span></p>
        </div>
        <div className="matrix_item macbook">
          <p><span>Usarás equipo de cómputo <span className="bold">MacBook Pro</span> de última generación</span></p>
        </div>
      </div>
    </div>
    <TitleH2 titleName="Proceso de ingreso" align="center bkg-white"></TitleH2>
    <div className="container-warped-agencia bkg-white">
      <div className="image-full show-desktop">
        <ImageProceso/>
      </div>
      <div className="image-full show-mobile">
        <ImageProcesoMovil/>
      </div>
    </div>
    <div className="container padding-0">
      <div className="container-warped-agencia">
        <div className="block">
          <div className="block-item cursos bkg-orange">
            <h3 className="cursos">Cupo</h3>
            <p>
              Buscamos a 10 desarrolladores de Android
            </p>
          </div>
          <div className="block-item cursos bkg-dark-orange">
            <h3 className="cursos">Calendario</h3>
            <p>
            Inicio de Academia: 24 de Junio<br />
            Fecha límite de inscripción: 10 de Junio</p>
          </div>
          <div className="block-item cursos bkg-light-blue">
            <h3 className="cursos">Lugar</h3>
            <p>Ciudad de México</p>
          </div>
          <div className="block-item cursos bkg-blue">
            <h3 className="cursos">Horario de capacitación</h3>
            <p>Disponibilidad de lunes a viernes de 9 a.m. a 6:30 p.m.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-padding bkg-white"></div>
  </Layout>
)

export default AcademiaDeSoftware
