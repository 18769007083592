import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image'

const BackgroundPerfiles = () => (
  <StaticQuery query = { graphql `
      query {
        desktop: file(relativePath: { eq: "background-perfiles.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    ` }
  render = {
    data => {
      const imageData = data.desktop.childImageSharp.fluid
      return ( <BackgroundImage Tag = "section"
            fluid = { imageData }
            className="background-perfiles"
            backgroundColor = { `#FFF` } >
            <div className="perfiles-message">
              <h3 className="text-white">Android</h3>
              <p>
                <span className="text-orange">Reto: </span>
                <span className="text-white">
                  Construir una aplicación nativa en <br />Android utilizando Java.
                </span>
              </p>
              <p>
                <span className="text-orange">Requisitos: </span>
                <span className="text-white">
                  Tener conocimientos básicos en <br />Android(Java) <br />
                  De preferencia, haber desarrollado una <br />aplicación nativa en este lenguaje.
                </span>
              </p>
            </div>
        </BackgroundImage>
      )
    }
  }
  />
)


export default BackgroundPerfiles