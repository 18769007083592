import React from "react"

const QueEs = () => (
  <>
    <div className="container padding-0 margin-top-1">
      <div className="container-warped-staffing">
        <div className="row">
          <div className="col">
            <div className="item-banner teoria sm">
              <p>Llevarás la teoría a la práctica en muy poco tiempo.</p>
            </div>
            <div className="item-banner tiempo sm">
              <p>Reducirás el tiempo requerido de estudio.</p>
            </div>
            <div className="item-banner esquema sm">
              <p>Aprenderás bajo un esquema de capacitación altamente personalizado.</p>
            </div>
            <div className="item-banner aprendizaje sm">
              <p>Acelerarás la curva de aprendizaje.</p>
            </div>
          </div>
          <div className="col">
            <div className="item-banner conocimientos sm">
              <p>Adquirirás conocimientos por medio de la experiencia.</p>
            </div>
            <div className="item-banner programa sm ">
              <p>Estarás inmerso en un programa de aprendizaje total.</p>
            </div>
            <div className="item-banner soft-skills lg rows">
              <p>Desarrollarás los soft-skills que demanda la industria:</p>
              <ul className="list-banners">
                <li className="bullet-2">Creatividad</li>
                <li className="bullet-2">Trabajo en equipo</li>
                <li className="bullet-2">Pensamiento crítico</li>
                <li className="bullet-2">Orientación al servicio</li>
                <li className="bullet-2">Resolución de problemas complejos</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default QueEs