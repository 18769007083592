import React, { Component } from "react"
import * as emailjs from 'emailjs-com';
import { Link } from "gatsby"

class ContactoAcademia extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      active: false ,
      nombre : typeof window !== 'undefined' ? localStorage.getItem("nombre") : "",
      apellidopaterno : typeof window !== 'undefined' ? localStorage.getItem("apellidopaterno") : "",
      apellidomaterno : typeof window !== 'undefined' ? localStorage.getItem("apellidomaterno") : "",
      email : typeof window !== 'undefined' ? localStorage.getItem("email") : "",
      telefono : typeof window !== 'undefined' ? localStorage.getItem("telefono") : "",
      genero : typeof window !== 'undefined' ? localStorage.getItem("genero") : "",
      dia : typeof window !== 'undefined' ? localStorage.getItem("dia") : "",
      mes : typeof window !== 'undefined' ? localStorage.getItem("mes") : "",
      anio : typeof window !== 'undefined' ? localStorage.getItem("anio") : "",
      universidad : typeof window !== 'undefined' ? localStorage.getItem("universidad") : "",
      razon : typeof window !== 'undefined' ? localStorage.getItem("razon") : "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleSubmit = event => {
    event.preventDefault();
    emailjs.sendForm('gmail','contactoacademia', '#contactoacademia', 'user_kI39K1UNAGCmR7XJMmOaa')
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      this.setState({
          active: !this.state.active
        });
    }, (err) => {
      console.log('FAILED...', err);
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value =  target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

    localStorage.setItem(target.name, target.value);
  }

  handleClose = event => {
    this.setState({
      active: !this.state.active,
      nombre : "",
      apellidopaterno : "",
      apellidomaterno : "",
      email : "",
      telefono : "",
      genero : "",
      dia : "",
      mes : "",
      anio : "",
      universidad : "",
      razon : "",
    });
    localStorage.removeItem("nombre");
    localStorage.removeItem("apellidopaterno");
    localStorage.removeItem("apellidomaterno");
    localStorage.removeItem("email");
    localStorage.removeItem("telefono");
    localStorage.removeItem("genero");
    localStorage.removeItem("dia");
    localStorage.removeItem("mes");
    localStorage.removeItem("anio");
    localStorage.removeItem("universidad");
    localStorage.removeItem("razon");
  }

  handleClear = event => {
    this.setState({
      nombre : "",
      apellidopaterno : "",
      apellidomaterno : "",
      email : "",
      telefono : "",
      genero : "",
      dia : "",
      mes : "",
      anio : "",
      universidad : "",
      razon : "",
    });
    localStorage.removeItem("nombre");
    localStorage.removeItem("apellidopaterno");
    localStorage.removeItem("apellidomaterno");
    localStorage.removeItem("email");
    localStorage.removeItem("telefono");
    localStorage.removeItem("genero");
    localStorage.removeItem("dia");
    localStorage.removeItem("mes");
    localStorage.removeItem("anio");
    localStorage.removeItem("universidad");
    localStorage.removeItem("razon");
  }

  render() {
    return (
  <>    
  <div className={this.state.active ? 'hidden' : "form-floating" }>
    <span className="form-floating-title">Registro</span>
    <form id="contactoacademia" className="form-academia" onSubmit={this.handleSubmit}>
      <div className="form-academia-container">
        <div className="col-left">
          <div className="form-control nombre">
            <input
              type="text"
              name="nombre"
              id="nombre"
              placeholder="Nombre(s)*"
              value={this.state.nombre}
              onChange={this.handleInputChange}
              required/>
          </div>
          <div className="form-control">
            <input 
              type="text"
              name="apellidopaterno"
              id="apellidopaterno"
              value={this.state.apellidopaterno}
              onChange={this.handleInputChange}
              placeholder="Apellido paterno" />
          </div>
          <div className="form-control">
            <input 
              type="text"
              name="apellidomaterno"
              id="apellidomaterno"
              value={this.state.apellidomaterno}
              onChange={this.handleInputChange}
              placeholder="Apellido materno" />
          </div>
          <div className="form-control email">
            <input
              type="email"
              name="email"
              id="email"
              value={this.state.email}
              onChange={this.handleInputChange}
              placeholder="Email*"
              required />
          </div>
          <div className="form-control telefono">
            <input
              type="tel"
              name="telefono"
              id="telefono"
              value={this.state.telefono}
              onChange={this.handleInputChange}
              placeholder="Teléfono*"
              required/>
          </div>
          <div className="form-control genero">
            <span className="field-title">
              Genero*
            </span>
            <label for="mujer" className="margin-right-10">
              <input
                onChange={this.handleInputChange}
                id="mujer"
                type="radio"
                name="genero"
                checked={this.state.genero === "mujer"}
                value="mujer" />Mujer
            </label>
            <label for="hombre">
            <input 
              onChange={this.handleInputChange}
              id="hombre"
              type="radio"
              name="genero"
              checked={this.state.genero === "hombre"}
              value="hombre" />Hombre
            </label>
          </div>
        </div>
        <div className="col-right">
          <div className="form-control nacimiento">
            <span className="field-title">Fecha de nacimiento*</span>
            <div className="form-select">
              <select
                name="dia"
                id="dia"
                value={this.state.dia}
                onChange={this.handleInputChange}
                required>
                <option>Día</option>
                <option value="1">1</option> 
                <option value="2">2</option> 
                <option value="3">3</option> 
                <option value="4">4</option> 
                <option value="5">5</option> 
                <option value="6">6</option> 
                <option value="7">7</option> 
                <option value="8">8</option> 
                <option value="9">9</option> 
                <option value="10">10</option> 
                <option value="11">11</option> 
                <option value="12">12</option> 
                <option value="13">13</option> 
                <option value="14">14</option> 
                <option value="15">15</option> 
                <option value="16">16</option> 
                <option value="17">17</option> 
                <option value="18">18</option> 
                <option value="19">19</option> 
                <option value="20">20</option> 
                <option value="21">21</option> 
                <option value="22">22</option> 
                <option value="23">23</option> 
                <option value="24">24</option> 
                <option value="25">25</option> 
                <option value="26">26</option> 
                <option value="27">27</option> 
                <option value="28">28</option> 
                <option value="29">29</option> 
                <option value="30">30</option> 
                <option value="31">31</option> 
              </select>
            </div>
            <div className="form-select">
              <select
                name="mes"
                id="mes"
                value={this.state.mes}
                onChange={this.handleInputChange}
                required>
                <option>Mes</option>
                <option value="1">Enero</option> 
                <option value="2">Febrero</option> 
                <option value="3">Marzo</option> 
                <option value="4">Abril</option> 
                <option value="5">Mayo</option> 
                <option value="6">Junio</option> 
                <option value="7">Julio</option> 
                <option value="8">Agosto</option> 
                <option value="9">Septiembre</option> 
                <option value="10">Octubre</option> 
                <option value="11">Noviembre</option> 
                <option value="12">Diciembre</option> 
              </select>
            </div>
            <div className="form-select">
              <select
                name="anio"
                id="anio"
                value={this.state.anio}
                onChange={this.handleInputChange}>
                <option>Año</option>
                <option value="2000">2000</option>
                <option value="1999">1999</option>
                <option value="1998">1998</option>
                <option value="1997">1997</option>
                <option value="1996">1996</option>
                <option value="1995">1995</option>
                <option value="1994">1994</option>
                <option value="1993">1993</option>
                <option value="1992">1992</option>
                <option value="1991">1991</option>
                <option value="1990">1990</option>
                <option value="1989">1989</option>
                <option value="1988">1988</option>
                <option value="1987">1987</option>
                <option value="1986">1986</option>
                <option value="1985">1985</option>
                <option value="1984">1984</option>
                <option value="1983">1983</option>
                <option value="1982">1982</option>
                <option value="1981">1981</option>
                <option value="1980">1980</option>
                <option value="1979">1979</option>
                <option value="1978">1978</option>
                <option value="1977">1977</option>
                <option value="1976">1976</option>
                <option value="1975">1975</option>
                <option value="1974">1974</option>
                <option value="1973">1973</option>
                <option value="1972">1972</option>
                <option value="1971">1971</option>
                <option value="1970">1970</option>
              </select>
            </div>
          </div>
          <div className="form-control universidad">
          <input
            type="text"
            placeholder="Universidad*"
            name="universidad"
            id="universidad"
            value={this.state.universidad}
            onChange={this.handleInputChange}
            required/>
          </div>
          <div className="form-control">
          <textarea
            rows="3"
            name="razon"
            id="razon"
            value={this.state.razon}
            onChange={this.handleInputChange}
            placeholder="¿Por qué razón deseas integrarte a la Academia de software?"></textarea>
          </div>
          <div className="form-control">
            <span className="mensaje-privacidad">Al dar clic a "enviar" estás aceptando el <Link to="/aviso-de-privacidad">aviso de privacidad</Link></span>
          </div>
          <div className="form-button">
            <button className="btn-white" type="reset" onClick={this.handleClear}>Limpiar</button>
            <button type="submit">Enviar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div className={this.state.active ? "form-floating-send-ok" : "hidden"}>
      <h1 className="bold">¡Gracias!</h1>
      <p className="text-white">
        1. Te llegará un correo electrónico con los links para que realices los ejercicios: técnicos y psicométricos.
        <br /><br />
        2. Comunícate al siguiente número telefónico en caso de requerir más información. <br /><br />
      </p>
      <h5 className="bold">(52) 56 1173 3922</h5>
      <div className="form-button">
        <button type="button" onClick={this.handleClose} className="btn-white">Terminar</button>
      </div>
  </div>
  </>
  );
  }
}

export default ContactoAcademia